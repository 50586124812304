import { useEffect, useState } from "react"


export const useAlphabeticalOrder = ( initialValue = [] ) => {
    let initialList = [...initialValue];

    useEffect(() => {
      setOrderedList([...initialValue])
    }, [initialValue])
    

    const [ orderedList, setOrderedList ] = useState([...initialValue])
    const [ ascendant, setAscendant ] = useState(true)
    const [ isSorting, setIsSorting ] = useState(false)

    const sortObject = (order = true) => {
      setIsSorting(true)
      
      if(initialList.length > 0) {
        if(order) {
          initialList.sort((a, b) => a.nombre.normalize().localeCompare(b.nombre.normalize()));
        } else {
          initialList.sort((a, b) => b.nombre.normalize().localeCompare(a.nombre.normalize()));
        }
        
        setOrderedList([...initialList])
        setAscendant(order)
        setIsSorting(false)
      }
    }

    return {
        orderedList,
        ascendant,
        isSorting,
        sortObject,
    }

}

