import { NavLink } from "react-router-dom"

export const Nav = () => {
  return (
    <nav className="main-nav">
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'active' : ''
            }
            end
          >
            <div className="icon">
              <img src="./assets/home.svg" alt="" />
            </div>
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/alumnos"
            className={({ isActive }) =>
              isActive ? 'active' : ''
            }
            end
          >
            <div className="icon">
              <img src="./assets/alumno.svg" alt="" />
            </div>
            <span>Alumnos</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/calendario"
            className={({ isActive }) =>
              isActive ? 'active' : ''
            }
            end
          >
            <div className="icon">
              <img src="./assets/calendario.svg" alt="" />
            </div>
            <span>Calendario</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}
