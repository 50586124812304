import React from 'react'
import { Outlet } from 'react-router'

export const EmptyLayout = () => (
  <>
    <div className="main-body container is-max-desktop">
      <div className="route-container">
        <Outlet />
      </div>
    </div>
  </>
)
