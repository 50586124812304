import { useDispatch } from "react-redux";
import { RoutesComponent } from "./components";
import { today } from "./redux/slice/userSlice";

import 'bulma/css/bulma.css'
import './App.scss'


function App() {
  const dispatch = useDispatch();
  const date = new Date();

  dispatch(today(process.env.REACT_APP_TODAY.length > 0 ? process.env.REACT_APP_TODAY : `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`))

  return (
    <div className="App">
      <RoutesComponent />
    </div>
  );
}

export default App;
