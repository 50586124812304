import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form";

import { loginFromApi } from '../../redux/thunk/user';
import validationOptions from '../../functions/validation'
import { InputText } from '../../components';

export const Login = () => {
  // Inicializar formulario
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      usuario: '',
      password: ''
    }
  });

  // Destructuring de las opciones de validación
  const { email, required } = validationOptions;

  // Redux
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.user);

  // Navegación
  const navigate = useNavigate();
  
  // Método al hacer submit
  const onSubmit = data => {
    dispatch(loginFromApi(data.usuario, data.password))

    setTimeout(() => {
      navigate("/");
    }, 500);
  }

  // JSX
  return (
    <div className="home container">
      <div className="columns is-multiline pt-3 pb-3">
        <div className="column pt-0 px-0 pb-6">
          <h4>LOGIN</h4>
        </div>
        
        <div className="column is-full p-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className='textList'>
              <li>
                <InputText
                  name="usuario"
                  label="Email"
                  placeholder="nombre@correo.cl"
                  control={control}
                  rules={{
                    ...required(),
                    ...email()
                  }}
                  errorMessage={errors?.usuario?.message}
                />
              </li>
              <li>
                <InputText
                  name="password"
                  label="Password"
                  placeholder="Ingrese su contraseña"
                  type="password"
                  control={control}
                  rules={{
                    ...required()
                  }}
                  errorMessage={errors?.password?.message}
                />
              </li>
              <li className='py-2'>
                <button type="submit" className='form-btn'>Login</button>
              </li>
              <li className='py-1 has-text-centered'>
                <Link to="/" className='link'>Olvidé mi contraseña</Link>
              </li>
              {
                error.length > 0 &&
                <li className='py-1 has-text-centered'>
                  <p className="text has-text-danger error-message">
                    {error}
                  </p>
                </li>
              }
            </ul>
          </form>
        </div>
      </div>
    </div>
  )
}
