import { Controller } from 'react-hook-form'

import { InputTextBase } from './InputTextBase'
import validationOptions from '../../../functions/validation'

export const InputText = ({
  name,
  control,
  rules,
  ...props
}) => {


  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <InputTextBase
            {...props}
            {...field}
            requiredInput={validationOptions.isRequired(rules)}
          />
        )
      }}
    />
  )
}
