import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paymentStatesList: [],
  calendarList: [],
  calendarViewHighlights: [],
  homeList: {
    today: [],
    next: [],
    countToday: 0,
    countNext: 0
  },
  studentList: {
    today: [],
    last: {},
    previous: [],
  },
  viewingStudent: '',
  viewing: {},
  creating: {},
  isEditing: false,
  isLoading: true,
}

export const appointmentsSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    endLoading: (state) => {
      state.isLoading = false
    },
    getTodayAppointments: (state, action) => {
      state.homeList.today = action.payload
    },
    getMoreTodayAppointments: (state, action) => {
      state.homeList.today = [...state.homeList.today, ...action.payload]
    },
    getNextAppointments: (state, action) => {
      state.homeList.next = action.payload
    },
    getMoreNextAppointments: (state, action) => {
      state.homeList.next = [...state.homeList.next, ...action.payload]
    },
    getTodayAppointmentsForStudent: (state, action) => {
      state.studentList.today = action.payload
    },
    getLastAppointmentsForStudent: (state, action) => {
      state.studentList.last = action.payload
    },
    getPreviousAppointmentsForStudent: (state, action) => {
      state.studentList.previous = action.payload
    },
    getViewingAppointment: (state, action) => {
      state.viewing = action.payload;
    },
    insertCreatingStudentInAppointmentForm: (state, action) => {
      state.viewingStudent = action.payload;
    },
    cleanStudentInAppointmentForm: (state) => {
      state.viewingStudent = {}
    },
    totalTodayAppointments: (state, action) => {
      state.homeList.countToday = parseInt(action.payload.count)
    },
    totalNextAppointments: (state, action) => {
      state.homeList.countNext = parseInt(action.payload.count)
    },
    creatingAppointment: (state, action) => {
      state.creating = action.payload
    },
    insertStudentInAppointment: (state, action) => {
      state.creating.alumno_id = action.payload
    },
    cleanCreatingAppointment: (state) => {
      state.creating = {}
    },
    cleanViewingAppointment: (state) => {
      state.viewing = {}
    },
    isEditingAppointment: (state, action) => {
      state.isEditing = action.payload
    },
    getPaymentStatesList: (state, action) => {
      state.paymentStatesList = action.payload
    },
    getCalendarAppointments: (state, action) => {
      state.calendarList = action.payload
    },
    getCalendarViewHighlights: (state, action) => {
      state.calendarViewHighlights = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  startLoading,
  endLoading,
  getTodayAppointments,
  getMoreTodayAppointments,
  getNextAppointments,
  getMoreNextAppointments,
  getTodayAppointmentsForStudent,
  getLastAppointmentsForStudent,
  getPreviousAppointmentsForStudent,

  getViewingAppointment,
  insertCreatingStudentInAppointmentForm,
  cleanStudentInAppointmentForm,
  totalTodayAppointments,
  totalNextAppointments,
  creatingAppointment,
  cleanCreatingAppointment,
  cleanViewingAppointment,
  isEditingAppointment,
  getPaymentStatesList,
  getCalendarAppointments,
  getCalendarViewHighlights,
  insertStudentInAppointment
} = appointmentsSlice.actions

export default appointmentsSlice.reducer