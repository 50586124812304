import { 
  endLoading,
  getTodayAppointments,
  getMoreTodayAppointments,
  getNextAppointments,
  getMoreNextAppointments,
  getViewingAppointment,
  totalTodayAppointments,
  totalNextAppointments,
  startLoading,
  getPaymentStatesList,
  getCalendarAppointments,
  getTodayAppointmentsForStudent,
  getLastAppointmentsForStudent,
  getPreviousAppointmentsForStudent,
  getCalendarViewHighlights,
} from '../slice/appointmentsSlice'
import { interceptor } from '../../api/interceptor'

export const getTodayAppointmentsFromApi = (id, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data:dataHoras } = await interceptor.get(`?metodo=todayAppointments&limit=5&profesor_id=${id}&min_date=${fecha}&offset=0`);
    dispatch(getTodayAppointments(dataHoras))

    const { data:dataTotalHoras } = await interceptor.get(`?metodo=countTodayAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    dispatch(totalTodayAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}

export const getMoreTodayAppointmentsFromApi = (id, fecha, offset = 0) => {
  return async(dispatch) => {

    dispatch(startLoading())
    
    // Peticion GET
    const { data:dataHoras } = await interceptor.get(`?metodo=todayAppointments&limit=5&profesor_id=${id}&min_date=${fecha}&offset=${offset}`);
    dispatch(getMoreTodayAppointments(dataHoras))

    const { data:dataTotalHoras } = await interceptor.get(`?metodo=countTodayAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    dispatch(totalTodayAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}

export const getNextAppointmentsFromApi = (id, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data:dataHoras } = await interceptor.get(`?metodo=nextAppointments&limit=5&profesor_id=${id}&min_date=${fecha}&offset=0`);
    dispatch(getNextAppointments(dataHoras))

    const { data:dataTotalHoras } = await interceptor.get(`?metodo=countNextAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    dispatch(totalNextAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}

export const getMoreNextAppointmentsFromApi = (id, fecha, offset = 0) => {
  return async(dispatch) => {

    dispatch(startLoading())
    
    // Peticion GET
    const { data:dataHoras } = await interceptor.get(`?metodo=nextAppointments&limit=5&profesor_id=${id}&min_date=${fecha}&offset=${offset}`);
    dispatch(getMoreNextAppointments(dataHoras))

    const { data:dataTotalHoras } = await interceptor.get(`?metodo=countNextAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    dispatch(totalNextAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}

export const getViewingAppointmentFromApi = (id) => {
  
  return async(dispatch) => {

    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=appointmentById&hora_id=${id}`);
    dispatch(getViewingAppointment(data))

    dispatch(endLoading())
  }
}

export const getAppointmentsDateFromApi = (id, fechaMin, fechaMax) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=appointmentsByDateRange&profesor_id=${id}&min_date=${fechaMin}&max_date=${fechaMax}&limit=0&offset=0`);
    // dispatch(getTodayAppointments(dataHoras))
    const dates = data.map(d => new Date(`${d.fecha}T00:03`))
    // let chars = ['A', 'B', 'A', 'C', 'B'];
    let uniqueDates = [...new Set(dates)];
    console.log(uniqueDates)

    dispatch(getCalendarViewHighlights(uniqueDates))

    dispatch(endLoading())
  }
}

export const getPaymentStatesListFromApi = () => {
  
  return async(dispatch) => {
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=paymentStateList`);
    dispatch(getPaymentStatesList(data))
  }
}

export const insertNewAppointmentFromApi = (appointment) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "POST");
  bodyFormData.append("titulo", appointment.titulo);
  bodyFormData.append("fecha", appointment.fecha);
  bodyFormData.append("hora", appointment.hora);
  bodyFormData.append("ubicacion", appointment.ubicacion);
  bodyFormData.append("observaciones", appointment.observaciones);
  bodyFormData.append("link", appointment.link);
  bodyFormData.append("estado_pago_id", appointment["estado_pago_id"]);
  bodyFormData.append("profesor_id", appointment["profesor_id"]);
  bodyFormData.append("alumno_id", appointment["alumno_id"]);

  return async(dispatch) => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=newAppointment`,
      data: bodyFormData,
    })
    // dispatch(getPaymentStatesList(data))
  }
}

export const updateAppointmentFromApi = (appointment) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "PUT");
  bodyFormData.append("id", appointment.id);
  bodyFormData.append("titulo", appointment.titulo);
  bodyFormData.append("fecha", appointment.fecha);
  bodyFormData.append("hora", appointment.hora);
  bodyFormData.append("ubicacion", appointment.ubicacion);
  bodyFormData.append("observaciones", appointment.observaciones);
  bodyFormData.append("link", appointment.link);
  bodyFormData.append("estado_pago_id", appointment["estado_pago_id"]);
  bodyFormData.append("profesor_id", appointment["profesor_id"]);
  bodyFormData.append("alumno_id", appointment["alumno_id"]);

  return async(dispatch) => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=updateAppointment`,
      data: bodyFormData,
    })
  }
}

export const deleteAppointmentFromApi = (id) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "DELETE");
  bodyFormData.append("id", id);

  return async(dispatch) => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=deleteAppointment`,
      data: bodyFormData,
    })
  }
}

// Student View
export const getTodayAppointmentsForStudentFromApi = (id, alumnoId, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=todayAppointmentsByStudentId&limit=5&profesor_id=${id}&alumno_id=${alumnoId}&min_date=${fecha}&offset=0`);
    dispatch(getTodayAppointmentsForStudent(data))

    // const { data:dataTotalHoras } = await interceptor.get(`?metodo=countTodayAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    // dispatch(totalTodayAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}

export const getLastAppointmentByStudentIdApi = (id, alumnoId, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=lastAppointmentsByStudentId&profesor_id=${id}&alumno_id=${alumnoId}&min_date=${fecha}`);
    dispatch(getLastAppointmentsForStudent(data))

    dispatch(endLoading())
  }
}

export const getPreviousAppointmentsForStudentFromApi = (id, alumnoId, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=previousAppointmentsByStudentId&limit=5&profesor_id=${id}&alumno_id=${alumnoId}&min_date=${fecha}&offset=0`);
    dispatch(getPreviousAppointmentsForStudent(data))

    dispatch(endLoading())
  }
}

// Calendar View
export const getCalendarAppointmentsFromApi = (id, fecha) => {
  return async(dispatch) => {
    dispatch(startLoading())
    
    // Peticion GET
    const { data:dataHoras } = await interceptor.get(`?metodo=todayAppointments&limit=0&profesor_id=${id}&min_date=${fecha}&offset=0`);
    dispatch(getCalendarAppointments(dataHoras))

    // const { data:dataTotalHoras } = await interceptor.get(`?metodo=countTodayAppointmentsById&profesor_id=${id}&min_date=${fecha}`);
    // dispatch(getCalendarAppointments(dataTotalHoras))


    dispatch(endLoading())
  }
}