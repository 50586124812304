import { useCallback, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode'

import { hasJWT } from '../../functions/utils';
import { cleanStudentInAppointmentForm, insertCreatingStudentInAppointmentForm } from '../../redux/slice/appointmentsSlice';
import { useDispatch, useSelector } from 'react-redux';

const cleanLocationName = txt => {
  const location = txt.split('/');
  location.shift();

  return location;
}


export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();

  const location = useLocation()
  const navigate = useNavigate()
  const thisLocationStr = cleanLocationName(location.pathname);

  const { viewing } = useSelector(store => store.students)

  // * Función memorizada para evaluar si el token está vencido
  const isUserExpired = useCallback(
    () => {
      let answer = false;
      //
      const token = localStorage.getItem('token');
      const user = jwt(token);

      //
      const hoy = new Date();
      const fechaHoy = new Date(`${hoy.getFullYear()}-${String(hoy.getMonth() + 1).padStart(2, '0')}-${String(hoy.getDate()).padStart(2, '0')}T${String(hoy.getHours()).padStart(2, '0')}:${String(hoy.getMinutes()).padStart(2, '0')}:${String(hoy.getSeconds()).padStart(2, '0')}-0300`);
      
      const expirationDate = new Date(`${user.expiracion.fecha}T${user.expiracion.hora}`);
      
      if(expirationDate <= fechaHoy){
        answer = true;
      }
      return answer;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  // UseEffect para crear el intervalo que evalua la expiración del usuario
  useEffect(() => {
    // Creo intervalo de 1 segundo, que evalue si
    // el usuario logueado tiene la fecha expirada
    const interval = setInterval(() => {
      if(isUserExpired()){
        localStorage.removeItem('token');
        navigate( "/login")
      }
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  //
  if(thisLocationStr.indexOf('ver-alumno') >= 0){
    dispatch(insertCreatingStudentInAppointmentForm(viewing.nombre))
  } else if(thisLocationStr.indexOf('formulario-hora') < 0){
    dispatch(cleanStudentInAppointmentForm())
  }

  //
  if (!hasJWT()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />
  }

  // authorized so return child components
  return children;
}