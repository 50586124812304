import { Controller } from 'react-hook-form'

import { SelectBase } from './SelectBase'
import validationOptions from '../../../functions/validation'

export const Select = ({
  name,
  control,
  rules,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <SelectBase
            {...props}
            {...field}
            defaultValue={field.value}
            requiredInput={validationOptions.isRequired(rules)}
          />
        )
      }}
    />
  )
}
