import { forwardRef, useRef } from 'react'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { InputTextBase } from '../InputText/InputTextBase';

import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want

const CustomInput = forwardRef(({ label, errorMessage, requiredInput, ...props }, ref) => (
  <InputTextBase
    label={label}
    ref={ref}
    errorMessage={errorMessage}
    requiredInput={requiredInput}
    {...props}
  />
));

export const InputDatepickerBase = forwardRef((
  {
    label,
    type,
    textarea,
    errorMessage,
    required,
    ...props
  },
  ref
) => {
  const inputRef = useRef(null);
  
  return (
    <>
      <DatePicker
        {...props}
        ref={ref}
        locale={es}
        customInput={
          <CustomInput
            label={label}
            ref={inputRef}
            errorMessage={errorMessage}
            requiredInput={required}
          />
        }
      />
    </>
  )
})
