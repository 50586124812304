import { forwardRef, useEffect, useState } from 'react';
import ReactSelect from 'react-select'

export const SelectBase = forwardRef(({ label, errorMessage, requiredInput, ...props }, ref) => {
  const [error, setError] = useState('')

  useEffect(() => {
    setError(errorMessage ? errorMessage : '')
  }, [errorMessage])
  
  const colourStyles = {
    control: styles => ({
      ...styles,
      fontSize: '0.7rem',
      border: error.length > 0 ? "1px solid #f14668" : "1px solid #b5b5b5",
      "&:hover": {
        border: error.length > 0 ? "1px solid #f14668" : "1px solid #b5b5b5",
      }
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: '0.7rem'
      };
    },
  };

  return (
    <div className="input-wrapper">
      {
        label &&
        <label htmlFor={props.name}>
          <strong>{label} {requiredInput ? <span className="has-text-danger">*</span> : ''}</strong>
        </label>
      }
      <ReactSelect
        {...props}
        ref={ref}
        styles={colourStyles}
      />
      {
        error.length > 0 &&
        <p className="text has-text-danger error-message">
          {error}
        </p>
      }
    </div>
  )
});
