import {
  login
} from '../slice/userSlice';
import { interceptor } from '../../api/interceptor'

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const loginFromApi = (usuario, password) => {
  return async(dispatch) => {
    // Peticion GET
    if(!localStorage.getItem('token')) {
      await interceptor.get(`?metodo=login&usuario=${usuario}&password=${password}`)
        .then(({data}) => {
          if(!isEmpty(data)) {
            dispatch(login(true))
            localStorage.setItem('token', data);
          } else {
            dispatch(login(false))
            localStorage.removeItem('token');
          }
        })
    } else {
      dispatch(login(true))
    }

  }
}