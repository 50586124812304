import { forwardRef, useEffect, useState } from "react"

const authorizedType = (type) => {
  let authorized = false;

  switch (type) {
    case 'password':
      authorized = true;
      break;
  
    default:
      break;
  }

  return authorized;
}

export const InputTextBase = forwardRef((
  {
    label,
    type,
    textarea,
    errorMessage,
    requiredInput,
    ...props
  },
  ref
) => {
  const [error, setError] = useState('')

  useEffect(() => {
    setError(errorMessage ? errorMessage : '')
  }, [errorMessage])
  
  return (
    <div className="input-wrapper">
      {
        label &&
        <label htmlFor={props.name}>
          <strong>{label} {requiredInput ? <span className="has-text-danger">*</span> : ''}</strong>
        </label>
      }
      {
        textarea ?
        <textarea
          {...props}
          ref={ref}
          maxLength="1000"
          className={`textarea ${error.length > 0 ? 'is-danger' : ''}`}
        />
        :
        <input
          {...props}
          ref={ref}
          type={authorizedType(type) ? type : 'text'}
          className={`input ${error.length > 0 ? 'is-danger' : ''}`}
        />
      }
      {
        error.length > 0 &&
        <p className="text has-text-danger error-message">
          {error}
        </p>
      }
    </div>
  )
})
