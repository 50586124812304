import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import { NavLink } from 'react-router-dom'

import { Header, Nav } from '../'
import { insertStudentInAppointment } from '../../redux/slice/appointmentsSlice'

export const Layout = () => {
  const dispatch = useDispatch();
  const { viewing:student } = useSelector(state => state.students)

  const insertStudentInCreating = () => {
    if(Object.keys(student).length > 0) dispatch(insertStudentInAppointment(parseInt(student.id)))
  }

  return (
    <>
      <Header />
      <div className="main-body container is-max-desktop">
        <div className="route-container">
          <Outlet />
          <NavLink
            to="/formulario-hora/creacion"
            state={{
              action: 'creación',
              type: 'hora'
            }}
            onClick={insertStudentInCreating}
            className="floating-button"
          >
            <img src="assets/hora.svg" alt="" />
          </NavLink>
        </div>
      </div>
      <Nav />
    </>
  )
}
