import { Logout } from "./Logout"

export const Header = () => {
  return (
    <header className="main-header">
      <div className="principal-container is-align-items-center is-justify-content-flex-end">
        <Logout />
      </div>
    </header>
  )
}
