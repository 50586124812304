import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { startLoading, endLoading, creatingStudent } from '../../redux/slice/studentsSlice'
import { getDataFromToken } from '../../functions/utils'
import validationOptions from '../../functions/validation';
import {
  InputDatepicker,
  InputOld,
  InputText,
  Select
} from '../../components';


export const MakeStudent = () => {
  // * REACT ROUTER Location
  const location = useLocation();
  const { from, action, type } = location.state;

  // *
  const dispatch = useDispatch();
  const id = getDataFromToken('id');
  const { viewing, isLoading } = useSelector(state => state.students)

  const genderList = [
    { value: '1', label: 'Masculino' },
    { value: '2', label: 'Femenino' },
    { value: '3', label: 'Otro' },
  ]

  // Inicializar formulario
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
    defaultValues: {
      nombre: '',
      genero: '',
      fechaNac: '',
      edad: '',
      rut: '',
      telefono: '',
      email: '',
      direccion: ''
    }
  });

  // Destructuring de las opciones de validación
  const { required } = validationOptions;

  // *
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(endLoading())
  }, [])
  

  useEffect(() => {
    switch(action) {
      case 'edición':
        dispatch(startLoading())

        setValue('nombre', viewing.nombre);
        setValue('genero', genderList.length > 0 ? genderList.find(gender => gender.label === viewing.genero) : '');
        setValue('fechaNac', viewing['fecha_nac'] ? (new Date(`${viewing['fecha_nac']}T23:00-0300`)) : '');
        setValue('edad', viewing.edad ? viewing.edad : '');
        setValue('rut', viewing.rut);
        setValue('telefono', viewing.telefono);
        setValue('email', viewing.email);
        setValue('direccion', viewing.direccion);

        setTimeout(() => {
          dispatch(endLoading())
        }, 200);
        break;

      case 'creación':
        dispatch(startLoading())

        setValue('nombre', '');
        setValue('genero', '');
        setValue('fechaNac', '');
        setValue('edad', '');
        setValue('rut', '');
        setValue('telefono', '');
        setValue('email', '');
        setValue('direccion', '');
        
        setTimeout(() => {
          dispatch(endLoading())
        }, 200);
        break;
    
      default:
        break;
    }
  }, [])

  // SUBMIT
  const onSubmit = ({ nombre, fechaNac, genero, edad, rut, telefono, email, direccion }) => {
    dispatch(creatingStudent({
      id: viewing.id, 
      nombre: nombre,
      'fecha_nac': fechaNac ? `${fechaNac.getFullYear()}-${String(fechaNac.getMonth() + 1).padStart(2, '0')}-${String(fechaNac.getDate()).padStart(2, '0')}` : '',
      genero: genero ? genero.label : '',
      edad: edad,
      rut: rut,
      telefono: telefono,
      email: email,
      direccion: direccion,
      password: '1234',
      'profesor_id': id,
    }))

    navigate( "/confirmar", {
      state:
        {
          from,
          action,
          type
        }
      }
    );
  }

  
  return (
    <div className="home container">
      <div className="columns is-multiline pt-3 pb-3">
        <div className="column pt-0 px-0 pb-6">
          <h4>Crear nuevo alumno</h4>
        </div>
        
        <div className="column is-full p-0">
        {
          !isLoading ?
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className='textList'>
              <li>
                <InputText
                  name="nombre"
                  label="Nombre"
                  placeholder=""
                  control={control}
                  rules={{
                    ...required()
                  }}
                  errorMessage={errors?.nombre?.message}
                />
              </li>

              <li>
                <InputDatepicker
                  name="fechaNac"
                  control={control}
                  dateFormat="dd/MM/yyyy"
                  label="Fecha de nacimiento"
                  errorMessage={errors?.fecha?.message}
                />
              </li>

              <li>
                <Select
                  name="genero"
                  label="Género"
                  control={control}
                  options={genderList}
                  placeholder="Selecciona un genero..."
                  errorMessage={errors?.genero?.message}
                />
              </li>
              <li>
                <InputText
                  name="edad"
                  label="Edad"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.edad?.message}
                />
              </li>
              <li>
                <InputText
                  name="rut"
                  label="RUT"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.rut?.message}
                />
              </li>
              <li>
                <InputText
                  name="telefono"
                  label="Teléfono"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.telefono?.message}
                />
              </li>
              <li>
                <InputText
                  name="email"
                  label="E-mail"
                  placeholder=""
                  control={control}
                  rules={{
                    ...required()
                  }}
                  errorMessage={errors?.email?.message}
                />
              </li>
              <li>
                <InputText
                  name="direccion"
                  label="Dirección"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.direccion?.message}
                />
              </li>
              <li className='py-2'>
                <button type="submit" className='form-btn'>Guardar</button>
              </li>
              <li className='py-1 has-text-centered'>
                <Link to={{ pathname: from, state: { from: location.pathname } }} className='link'>Volver</Link>
              </li>
            </ul>
          </form>
          :
          <></>
        }
        </div>

      </div>
    </div>
  )
}