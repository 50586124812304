import { configureStore } from '@reduxjs/toolkit'
import { userReducer, appointmentsReducer, studentsReducer } from '../slice'

// import userReducer from '../slice/userSlice'
// import appointmentsReducer from '../slice/appointmentsSlice'
// import studentsReducer from '../slice/studentsSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    appointments: appointmentsReducer,
    students: studentsReducer
  },
})