import { useNavigate } from 'react-router-dom';
import { logout } from '../../functions/utils';

export const Logout = () => {
  const navigate = useNavigate();

  const logoutHandler = (e) => {
    e.preventDefault();
    logout();

    setTimeout(() => {
      navigate('/login')
    }, 500);
  }

  return (
    <a onClick={logoutHandler} className="link" href="/#">
      <img src="assets/logout.svg" alt="" />
    </a>
  )
}
