import { useState } from "react";
import { formatDate } from "../functions/utils";

export const StudentCard = ({ index, titulo, ubicacion, fecha, hora, observaciones }) => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <div className="column is-full mt-1 mb-1 pt-0 px-0 pb-1" key={`hora_${index}`}>
      <div className='card'>
        <p className='text'><strong>{ titulo }</strong></p>
        <p className='text'>{ ubicacion }</p>
        <p className='text'>{ formatDate(fecha) } - { hora }</p>

        {
          viewMore ?
          <>
            <p className='text mt-2'><strong>Observaciones</strong></p>
            <p className='text'>{ observaciones }</p>
          </>
          :
          <></>
        }
        <div className="column p-0 mt-2 is-align-items-end">
          <p className="link text-align-right" onClick={() => setViewMore(!viewMore)}>{ viewMore ? 'Ver menos...' : 'Ver más...' }</p>
        </div>
      </div>
    </div>
  )
}
