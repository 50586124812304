import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: '',
  today: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      if(action.payload) {
        state.error = ''
      } else {
        state.error = 'Usuario o Password erroneo.'
      }
    },
    today: (state, action) => {
      state.today = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  login,
  today,
} = userSlice.actions

export default userSlice.reducer