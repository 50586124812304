import { Routes, Route, Navigate } from "react-router-dom";
import { Layout, EmptyLayout, PrivateRoute } from '../';
import {
  Home,
  MakeAppointment,
  ViewAppointment,
  StudentView,
  CalendarView,
  ConfirmPrompt,
  Checkout,
  Login,
  ViewStudent,
  MakeStudent
} from '../../pages'

export const RoutesComponent = () => {
  // const params = useParams();

  return (
    <Routes>
      <Route
        path="/"
        element={<EmptyLayout />}
      >
        <Route exact path="/login" element={<Login />} />
      </Route>

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route exact index element={<Home />} />
        
        <Route exact path="/calendario" element={<CalendarView />} />
        <Route exact path="/ver-hora/:appointmentId" element={<ViewAppointment />} />
        <Route exact path="/formulario-hora/:action" element={<MakeAppointment />} />
        <Route exact path="/formulario-hora/:action/:id" element={<MakeAppointment />} />
        <Route exact path="/ver-alumno/:studentId" element={<ViewStudent />} />
        
        <Route exact path="/alumnos" element={<StudentView />} />
        <Route exact path="/formulario-alumno" element={<MakeStudent />} />

        <Route path="/confirmar" element={<ConfirmPrompt />} />
        <Route path="/checkout" element={<Checkout />} />

        {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        {/* <Route path="*" element={<NoMatch />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  )
}
