import {
  startLoading,
  endLoading,
  getStudentsList,
  getEditingStudent,
  getStudentsViewList,
  getViewingStudent,
} from '../slice/studentsSlice';
import { interceptor } from '../../api/interceptor'

export const getStudentsListFromApi = (id) => {
  return async(dispatch) => {
    // Peticion GET
    await interceptor.get(`?metodo=studentsList&profesor_id=${id}`)
      .then(({data}) => {
        dispatch(getStudentsList(data))
      })

  }
}

export const getEditingStudentFromApi = (name) => {
  return async(dispatch) => {
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=studentByName&alumno_nombre=${name}`);
    dispatch(getEditingStudent(data))
  }
}

export const getAllStudentsForViewFromApi = (id, today) => {
  return async(dispatch) => {
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=studentsListView&profesor_id=${id}&min_date=${today}`);
    dispatch(getStudentsViewList(data))
  }
}

export const getViewingStudentFromApi = (id) => {
  
  return async(dispatch) => {

    dispatch(startLoading())
    
    // Peticion GET
    const { data } = await interceptor.get(`?metodo=studentById&alumno_id=${id}`);
    dispatch(getViewingStudent(data))

    dispatch(endLoading())
  }
}

export const insertNewStudentFromApi = (student) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "POST");
  bodyFormData.append("nombre", student.nombre);
  bodyFormData.append("fecha_nac", student['fecha_nac']);
  bodyFormData.append("genero", student.genero);
  bodyFormData.append("edad", student.edad);
  bodyFormData.append("rut", student.rut);
  bodyFormData.append("telefono", student.telefono);
  bodyFormData.append("email", student.email);
  bodyFormData.append("direccion", student.direccion);
  bodyFormData.append("password", student.password);
  bodyFormData.append("profesor_id", student["profesor_id"]);

  return async() => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=newStudent`,
      data: bodyFormData,
    })
  }
}


export const updateStudentFromApi = (student) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "PUT");
  bodyFormData.append("id", student.id);
  bodyFormData.append("nombre", student.nombre);
  bodyFormData.append("fecha_nac", student['fecha_nac']);
  bodyFormData.append("genero", student.genero);
  bodyFormData.append("edad", student.edad);
  bodyFormData.append("rut", student.rut);
  bodyFormData.append("telefono", student.telefono);
  bodyFormData.append("email", student.email);
  bodyFormData.append("direccion", student.direccion);
  bodyFormData.append("password", student.password);
  bodyFormData.append("profesor_id", student["profesor_id"]);

  return async() => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=updateStudent`,
      data: bodyFormData,
    })
  }
}

export const deleteStudentFromApi = (id) => {
  var bodyFormData = new FormData();
  bodyFormData.append("METHOD", "DELETE");
  bodyFormData.append("id", id);

  return async() => {
    // Peticion POST
    await interceptor({
      method: "post",
      url: `?metodo=deleteStudent`,
      data: bodyFormData,
    })
  }
}