import jwt from 'jwt-decode'

export const getIsObjectSorted = (arr, key) => {
  let second_index;

  // * Comprobar si el array está vacio
  if(arr.length <= 0) return false;

  // * Bucle donde se analiza que el objeto anterior es mayor o menor al siguiente
  for(let first_index = 0; first_index < arr.length; first_index++){
    second_index = first_index + 1;
    if(arr[first_index][key].normalize().localeCompare(arr[second_index][key].normalize())) return false;
  }

  return true;
}

export const formatDate = txt => {
  if(txt){
    const splittedTxt = txt.split('-');

    return `${splittedTxt[2]}/${splittedTxt[1]}/${splittedTxt[0]}`;
  }
  return;
}

export const hasJWT = () => {
  let flag = false;

  // check user has JWT token
  localStorage.getItem('token') ? flag = true : flag = false;
 
  return flag;
}

export const getDataFromToken = (prop) => {
  const token = localStorage.getItem('token');
  const user = jwt(token);

  return user[prop];
}

export const logout = () => {
  localStorage.removeItem('token');
}


export const getTextDayOfWeek = (day) => {
  let thisDay = null;

  switch (day) {
    case 0:
      thisDay = 'Lunes';
      break;

    case 1:
      thisDay = 'Martes';
      break;

    case 2:
      thisDay = 'Miércoles';
      break;

    case 3:
      thisDay = 'Jueves';
      break;

    case 4:
      thisDay = 'Viernes';
      break;

    case 5:
      thisDay = 'Sábado';
      break;

    case 6:
      thisDay = 'Domingo';
      break;
  
    default:
      break;
  }

  return thisDay;
}

export const getTextMonth = (day) => {
  let thisDay = null;

  switch (day) {
    case 0:
      thisDay = 'Enero';
      break;

    case 1:
      thisDay = 'Febrero';
      break;

    case 2:
      thisDay = 'Marzo';
      break;

    case 3:
      thisDay = 'Abril';
      break;

    case 4:
      thisDay = 'Mayo';
      break;

    case 5:
      thisDay = 'Junio';
      break;
      
    case 6:
      thisDay = 'Julio';
      break;

    case 7:
      thisDay = 'Agosto';
      break;

    case 8:
      thisDay = 'Septiembre';
      break;

    case 9:
      thisDay = 'Octubre';
      break;

    case 10:
      thisDay = 'Noviembre';
      break;

    case 11:
      thisDay = 'Diciembre';
      break;
  
    default:
      break;
  }

  return thisDay;
}

export const replaceTick = (cadena) => {
  const chars = {
    "á":"a", "é":"e", "í":"i", "ó":"o", "ú":"u",
    "à":"a", "è":"e", "ì":"i", "ò":"o", "ù":"u", "ñ":"n",
    "Á":"A", "É":"E", "Í":"I", "Ó":"O", "Ú":"U",
    "À":"A", "È":"E", "Ì":"I", "Ò":"O", "Ù":"U", "Ñ":"N"
  }

  const expr = /[áàéèíìóòúùñ]/ig;

  const res = cadena.replace(expr, function(e){return chars[e]});

  return res;
}