import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getViewingAppointmentFromApi } from '../redux/thunk/appointments';

export const CalendarAppointment = ({ id, titulo, alumno, ubicacion, hora }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editClickHandler = (e, id) => {
    e.preventDefault();

    dispatch(getViewingAppointmentFromApi(id))
    
    setTimeout(() => {
      navigate(
        "/formulario-hora",
        {
          state: {
            from: `/ver-hora/${id}`,
            action: 'edición',
            type: 'hora'
          }
        }
      )
    }, 100);
  }

  const deleteClickHandler = (e, id) => {
    e.preventDefault();

    dispatch(getViewingAppointmentFromApi(id))
    
    setTimeout(() => {
      navigate(
        "/confirmar",
        {
          state: {
            from: `/ver-hora/${id}`,
            action: 'eliminación',
            type: 'hora'
          }
        }
      )
    }, 100);
  }


  return (
    <>
      <div className="column p-0 is-flex is-align-items-flex-start is-justify-content-space-between">
        <Link className="link" to={`/ver-hora/${id}`}>{titulo} - { alumno }</Link>

        <div className="actions is-flex">
          <a
            href="/#"
            onClick={e => editClickHandler(e, id)}
            className='is-size-7 mr-2'
          >
            <img src="/assets/editar.svg" style={{height: "16px"}} alt="" />
          </a>
          <a
            href="/#"
            onClick={e => deleteClickHandler(e, id)}
            className='is-size-7'
          >
            <img src="/assets/eliminar.svg" style={{height: "16px"}} alt="" />
          </a>
        </div>
      </div>
      <p className='text'>{ubicacion}</p>
      <p className='text'>{hora}</p>
    </>
  )
}
