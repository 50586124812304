import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

import { formatDate, getDataFromToken } from "../../functions/utils";
import { getAllStudentsForViewFromApi } from "../../redux/thunk/students";
import { useAlphabeticalOrder } from "../../hooks/useAlphabeticalOrder";

export const StudentView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  
  const { today } = useSelector(state => state.user)
  const { studentsViewList } = useSelector(state => state.students)
  
  const id = getDataFromToken('id');

  const { orderedList, ascendant, isSorting, sortObject } = useAlphabeticalOrder(studentsViewList)

  const makeNewStudent = () => {
    navigate(
      "/formulario-alumno",
      {
        state: {
          from: location.pathname,
          action: 'creación',
          type: 'alumno'
        }
      }
    )
  }

  useEffect(() => {
    dispatch(getAllStudentsForViewFromApi(id, today));
  }, [])

  return (
    <div className="home inner-container container pb-4">
      <div className="columns is-multiline pt-3 px-0 pb-3">
        <div className="column is-full pt-0 px-0 pb-1">
          <h4>Ver alumnos</h4>
        </div>

        <div className="column is-full is-align-content-center p-0 mt-4 mb-5">
          <button onClick={makeNewStudent} className='form-btn'>Agregar nuevo alumno</button>
        </div>
        <div className="column is-full is-align-content-center p-0 mb-2">
          <span className="link" onClick={() => sortObject(!ascendant)}>Ordenar {ascendant ? 'A-Z' : 'Z-A'} {ascendant ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</span>
        </div>

          
        { !isSorting ?
          <>
            {
              orderedList.map((student, index) => {
                const { id: alumnoId, nombre, fecha_siguiente, hora_siguiente, fecha_anterior, ubicacion_siguiente } = student;

                return (
                  <div className="column is-full pt-0 px-0 pb-1" key={`alumno_${index}`}>
                    <div className='card'>
                      <Link to={`/ver-alumno/${alumnoId}`}>{ nombre }</Link>
                      <p className='text'>{ fecha_siguiente ? `Próxima sesión: ${formatDate(fecha_siguiente)} - ${hora_siguiente}` : 'Sin sesiones pendientes' }</p>
                      <p className='text'>{ fecha_siguiente ? ubicacion_siguiente : (fecha_anterior ? `Ultima sesión: ${formatDate(fecha_anterior)}` : 'No tiene sesiones anteriores') }</p>
                    </div>
                  </div>
                )
              })
            }
          </>
          :
          <></>
        }
        
      </div>
    </div>
  )
}
