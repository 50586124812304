import { Controller } from 'react-hook-form'

import { InputDatepickerBase } from './InputDatepickerBase'
import validationOptions from '../../../functions/validation'

export const InputDatepicker = ({
  name,
  control,
  rules,
  ...props
}) => {
  
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InputDatepickerBase
          {...field}
          {...props}
          selected={field.value}
          required={validationOptions.isRequired(rules, 'datepicker')}
        />
      )}
    />
  )
}
