import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import {
  creatingAppointment,
  startLoading,
  endLoading,
} from '../../redux/slice/appointmentsSlice'
import {
  InputDatepicker,
  InputText,
  Select
} from '../../components';

import { getStudentsListFromApi } from '../../redux/thunk/students';
import { getPaymentStatesListFromApi } from '../../redux/thunk/appointments';

import { getDataFromToken } from '../../functions/utils'
import validationOptions from '../../functions/validation';


export const MakeAppointment = () => {
  // * REACT ROUTER Location
  const location = useLocation();
  const { /* action, */ type } = location.state;

  const { action } = useParams();

  const from = '/';

  // *
  const dispatch = useDispatch();
  const id = getDataFromToken('id');
  const { paymentStatesList, viewing, creating, viewingStudent } = useSelector(state => state.appointments)
  const { studentsList } = useSelector(state => state.students)

  // Inicializar formulario
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
    defaultValues: {
      titulo: '',
      fecha: null,
      hora: null,
      alumno: null,
      ubicacion: '',
      estado: null,
      observaciones: ''
    }
  });

  // Destructuring de las opciones de validación
  const { required } = validationOptions;

  //
  const navigate = useNavigate();

  //
  useEffect(() => {
    dispatch(getStudentsListFromApi(id))
    dispatch(getPaymentStatesListFromApi())

    switch(action) {
      case 'edicion':
        dispatch(startLoading())

        setValue('titulo', viewing.titulo);
        setValue('fecha', new Date(`${viewing.fecha}T${viewing.hora}-0300`));
        setValue('hora', new Date(`${viewing.fecha}T${viewing.hora}-0300`));
        setValue('alumno', studentsList.length > 0 ? studentsList.find(student => student.label === viewing.alumno) : '');
        setValue('ubicacion', viewing.ubicacion);
        setValue('estado', paymentStatesList.length > 0 ? paymentStatesList.find(paymentState => paymentState.label === viewing.estado_pago) : '');
        setValue('observaciones', viewing.observaciones);

        setTimeout(() => {
          dispatch(endLoading())
        }, 200);
        break;

      case 'creacion':
        setValue('titulo', '');
        setValue('fecha', '');
        setValue('hora', '');
        setValue('alumno', studentsList.length > 0 ? studentsList.find(student => student.label === viewingStudent) : '');
        setValue('ubicacion', '');
        setValue('estado', '')
        setValue('observaciones', '');
        break;
    
      default:
        break;
    }
  }, [])

  // ! SUBMIT
  const onSubmit = ({ titulo, fecha, hora, ubicacion, observaciones, estado, alumno }) => {
    dispatch(creatingAppointment({
      id: viewing.id,
      titulo: titulo,
      fecha: `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, '0')}-${String(fecha.getDate()).padStart(2, '0')}`,
      hora: `${String(hora.getHours()).padStart(2, '0')}:${String(hora.getMinutes()).padStart(2, '0')}`,
      ubicacion: ubicacion,
      observaciones: observaciones,
      link: 'prueba.com',
      'estado_pago_id': parseInt(estado.value),
      'profesor_id': id,
      'alumno_id': parseInt(alumno.value)
    }))

    navigate( "/confirmar", {
      state:
        {
          from: location.pathname,
          action,
          type
        }
      }
    );
  }
  
  return (
    <div className="home container">
      <div className="columns is-multiline pt-3 pb-3">
        <div className="column pt-0 px-0 pb-6">
          <h4>Agendar nueva hora</h4>
        </div>
        
        <div className="column is-full p-0">
        {/* {
          !isLoading ? */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className='textList'>
              <li>
                <InputText
                  name="titulo"
                  label="Título"
                  placeholder=""
                  control={control}
                  rules={{
                    ...required()
                  }}
                  errorMessage={errors?.titulo?.message}
                />
              </li>
              <li>
                <InputDatepicker
                  name="fecha"
                  control={control}
                  rules={{
                    ...required()
                  }}
                  dateFormat="dd/MM/yyyy"
                  label="Fecha"
                  errorMessage={errors?.fecha?.message}
                />
              </li>
              <li>
                <InputDatepicker
                  name="hora"
                  control={control}
                  rules={{
                    ...required()
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  label="Hora"
                  errorMessage={errors?.hora?.message}
                />
              </li>
              <li>
                <Select
                  name="alumno"
                  label="Alumno"
                  control={control}
                  rules={{
                    ...required()
                  }}
                  options={studentsList}
                  placeholder="Selecciona un alumno..."
                  errorMessage={errors?.alumno?.message}
                />
              </li>
              <li>
                <InputText
                  name="ubicacion"
                  label="Ubicación"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.ubicacion?.message}
                />
              </li>
              <li>
                <Select
                  name="estado"
                  label="Estado del Pago"
                  control={control}
                  rules={{
                    ...required()
                  }}
                  options={paymentStatesList}
                  placeholder="Selecciona un estado..."
                  errorMessage={errors?.estado?.message}
                />
              </li>
              <li>
                <InputText
                  name="observaciones"
                  label="Observaciones"
                  placeholder=""
                  control={control}
                  errorMessage={errors?.observaciones?.message}
                  textarea
                />
              </li>
              <li className='py-2'>
                <button type='submit' className='form-btn'>Guardar</button>
              </li>
              <li className='py-1 has-text-centered'>
                <Link to={{ pathname: from, state: { from: location.pathname } }} className='link'>Volver</Link>
              </li>
            </ul>
          </form>
          {/* :
          <></>
        } */}
        </div>

      </div>
    </div>
  )
}