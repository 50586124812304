import { Link, useLocation } from 'react-router-dom';

export const Checkout = () => {
  const location = useLocation();

  const { action, type } = location.state;

  let sentence = '';
  switch (action) {
    case 'creación':
      sentence = 'Has agendado la hora exitosamente';
      break;
      
    case 'edición':
      sentence = 'Has editado la hora exitosamente';
      break;

    case 'eliminación':
      sentence = 'Has eliminado la hora exitosamente';
      break;
  
    default:
      break;
  }

  return (
    <div className="checkout container">
      <div className="columns is-multiline">
        <div className="column is-full pt-0 px-0 pb-5">
          <h5 className='has-text-weight-normal'>{ sentence }</h5>
        </div>
        
        <div className="column is-full is-align-content-center pt-0 px-0 pb-3">
          <Link to="/" className='link'>Ir al Home</Link>
        </div>
      </div>
    </div>
  )
}
