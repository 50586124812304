import { getTextDayOfWeek, getTextMonth } from '../functions/utils';
import { CalendarAppointment } from './CalendarAppointment';

export const CalendarList = ({ list, date }) => {
  return (
    <>
      <div className="column is-full pt-0 px-0 pb-1">
        <h6>{ `${getTextDayOfWeek(date.getDay())} ${String(date.getDate()).padStart(2, '0')} de ${getTextMonth(date.getMonth())} de ${date.getFullYear()}` }</h6>
      </div>

      <ul>
        {
          list.map(appointment => {
            const { id, titulo, alumno, ubicacion, hora } = appointment;
            
            return (
              <li className='mt-2' key={`calendarListItem_${id}`}>
                <CalendarAppointment
                  id={id}
                  titulo={titulo}
                  alumno={alumno}
                  ubicacion={ubicacion}
                  hora={hora}
                />
              </li>
            )
          })
        }
      </ul>
    </>
  )
}
