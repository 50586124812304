const validationOptions = {
  email: (text = "Este campo debe tener formato email.") => {
    return {
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: text
      }
    }
  },
  required: (text = "Este campo es requerido.") => {
    return { required: text, }
  },
  minLength: (value = 4, text = "Este campo es requerido.") => {
    return {
      minLength: {
        value,
        message: text
      }
    }
  },

  isRequired: (rules, campo = '') => {
    if(rules) {
      return rules.hasOwnProperty('required');
    }
    return false;
  }
}

export default validationOptions;