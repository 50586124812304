import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom'
import { getViewingAppointmentFromApi } from '../../redux/thunk/appointments';
import { formatDate } from '../../functions/utils'


export const ViewAppointment = () => {
  // -------------------------------------- PARAMS
  const { appointmentId } = useParams();

  // -------------------------------------- REDUX
  const { viewing:appointment, isLoading } = useSelector(state => state.appointments)
  const dispatch = useDispatch();

  // -------------------------------------- USE EFFECT
  useEffect(() => {
    dispatch(getViewingAppointmentFromApi(appointmentId))
  }, [])

  return (
    <div className="home container">
      <div className="columns is-multiline pt-3 pb-3">
        <div className="column pt-0 px-0 pb-3">
          <h4>Hora agendada</h4>
        </div>

        <div className="column is-narrow pt-0 px-0 pb-6">
          <NavLink
            to={`/formulario-hora/edicion/${appointment.id}`}
            state={{
              from: `/ver-hora/${appointment.id}`,
              action: 'edición',
              type: 'hora'
            }}
            className='mr-2'
          >
            <img src="/assets/editar.svg" alt="" />
          </NavLink>
          <NavLink
            to='/confirmar'
            state={{
              from: `/ver-hora/${appointment.id}`,
              action: 'eliminación',
              type: 'hora'
            }}
          >
            <img src="/assets/eliminar.svg" alt="" />
          </NavLink>
        </div>
        
        <div className="column is-full p-0">
        {
          !isLoading ?
          <ul className='textList'>
            <li>
              <p className='text'><strong>Título</strong></p>
              <p className='text'>{appointment.titulo}</p>
            </li>
            <li>
              <p className='text'><strong>Fecha</strong></p>
              <p className='text'>{formatDate(appointment.fecha)}</p>
            </li>
            <li>
              <p className='text'><strong>Hora</strong></p>
              <p className='text'>{appointment.hora}</p>
            </li>
            <li>
              <p className='text'><strong>Alumno</strong></p>
              <p className='text'>{appointment.alumno}</p>
            </li>
            <li>
              <p className='text'><strong>Ubicación</strong></p>
              <p className='text'>{appointment.ubicacion}</p>
            </li>
            <li>
              <p className='text'><strong>Estado del Pago</strong></p>
              <p className='text'>{appointment.estado_pago}</p>
            </li>
            <li>
              <p className='text'><strong>Observaciones</strong></p>
              <p className='text'>{appointment.observaciones ? appointment.observaciones : 'Sin observaciones...'}</p>
            </li>
            <li>
              <p className='text'><strong>Link</strong></p>
              <p className='link'>{appointment.link}</p>
            </li>
          </ul>
          :
          <></>
        }
        </div>

      </div>
    </div>
  )
}
