import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getTodayAppointmentsFromApi,
  getMoreTodayAppointmentsFromApi,
  getNextAppointmentsFromApi,
  getMoreNextAppointmentsFromApi
} from '../../redux/thunk/appointments'
import { Card } from "../../components"
import { formatDate, getDataFromToken } from '../../functions/utils'

export const Home = () => {
  // -------------------------------------- REDUX
  const { today } = useSelector(state => state.user)
  const { homeList, isLoading } = useSelector(state => state.appointments)
  const dispatch = useDispatch();

  const id = getDataFromToken('id');

  // -------------------------------------- USE EFFECT
  useEffect(() => {
    dispatch(getTodayAppointmentsFromApi(id, today))
    dispatch(getNextAppointmentsFromApi(id, today))
  }, [])

  // -------------------------------------- HANDLERS
  const seeMoreTodayHandler = () => {
    dispatch(getMoreTodayAppointmentsFromApi(id, today, homeList.today.length))
  }

  const seeMoreNextHandler = () => {
    dispatch(getMoreNextAppointmentsFromApi(id, today, homeList.next.length))
  }
  
  return (
    <div className="home inner-container container pb-4">
      {
        !isLoading ?
        <>
          <div className="columns is-multiline pt-3 px-0 pb-3">
            <div className="column is-full pt-0 px-0 pb-1">
              <h4>Hoy</h4>
            </div>
            {
              !isLoading &&
                homeList?.today.length > 0 ?
                  <>
                    {
                      (homeList?.today.map(appointment => {
                        return (
                          <div key={`card_${appointment.id}`} className={`column pt-0 px-0 is-full`}>
                            <Card
                              id={appointment.id}
                              alumno={appointment.alumno}
                              titulo={appointment.titulo}
                              ubicacion={appointment.ubicacion}
                              hora={appointment.hora}
                              fecha={formatDate(appointment.fecha)}
                            />
                          </div>
                        )
                      }))
                    }

                    {
                      homeList?.today.length < homeList?.countToday ?
                      <div className="column px-0">
                        <p className="link" onClick={seeMoreTodayHandler}>Ver más...</p>
                      </div>
                      :
                      <></>
                    }
                  </>
                  :
                  <p>No tienes horas agendadas para hoy.</p>
            }
          </div>

          <div className="columns is-multiline">
            <div className="column is-full pt-0 px-0 pb-1">
              <h4>Próximas</h4>
            </div>
            {
              !isLoading &&
                homeList?.next.length > 0 ?
                  <>
                    {
                      (homeList?.next.map(appointment => {
                        return (
                          <div key={`card_${appointment.id}`} className="column is-full pt-0 px-0 pb-2">
                            <Card
                              id={appointment.id}
                              alumno={appointment.alumno}
                              titulo={appointment.titulo}
                              ubicacion={appointment.ubicacion}
                              hora={appointment.hora}
                              fecha={formatDate(appointment.fecha)}
                            />
                          </div>
                        )
                      }))
                    }
                    {
                      homeList?.next.length < homeList?.countNext ?
                      <div className="column px-0">
                        <p className="link" onClick={seeMoreNextHandler}>Ver más...</p>
                      </div>
                      :
                      <></>
                    }
                  </>
                  :
                  <p className='text'>No tienes horas agendadas para los próximos días.</p>
            }
          </div>
        </>
        :
        <div className="loading">LOADING</div>
      }
    </div>
  )
}