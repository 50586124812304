import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import _ from 'lodash';
import { getDataFromToken } from "../../functions/utils";
import {
  getLastAppointmentByStudentIdApi,
  getPreviousAppointmentsForStudentFromApi,
  getTodayAppointmentsForStudentFromApi
} from "../../redux/thunk/appointments";
import {
  getViewingStudentFromApi
} from "../../redux/thunk/students";
import { StudentCard } from "../../components";


export const ViewStudent = () => {
  const id = getDataFromToken('id')

  // -------------------------------------- PARAMS
  const { studentId } = useParams();

  // -------------------------------------- REDUX
  const { today } = useSelector(state => state.user)
  const { studentList } = useSelector(state => state.appointments)
  const { viewing:student, isLoading: studentIsLoading } = useSelector(state => state.students)
  const dispatch = useDispatch();

  // -------------------------------------- USE EFFECT
  useEffect(() => {
    dispatch(getViewingStudentFromApi(studentId))
  }, [])

  useEffect(() => {
    dispatch(getTodayAppointmentsForStudentFromApi(id, student.id, today))
    dispatch(getLastAppointmentByStudentIdApi(id, student.id, today))
    dispatch(getPreviousAppointmentsForStudentFromApi(id, student.id, today))
  }, [student])

  return (
    <div className="home inner-container container pb-4">
      {
        !studentIsLoading ?
        <>
          <div className="columns py-3 px-0">
            <div className="column is-narrow pt-0 px-0">
              <p className='inline-middle'>Nombre</p>
            </div>
            <div className="column p-0 pl-1">
              <h4 className='inline-middle'>{ student.nombre }</h4>
            </div>

            <div className="column is-narrow pt-0 px-0 pb-6">
              <NavLink
                to='/formulario-alumno'
                state={{
                  from: `/ver-alumno/${student.id}`,
                  action: 'edición',
                  type: 'alumno'
                }}
                className='mr-2'
              >
                <img src="/assets/editar.svg" alt="" />
              </NavLink>
              <NavLink
                to='/confirmar'
                state={{
                  from: `/ver-alumno/${student.id}`,
                  action: 'eliminación',
                  type: 'alumno'
                }}
              >
                <img src="/assets/eliminar.svg" alt="" />
              </NavLink>
            </div>
          </div>

          <div className="columns is-multiline pt-3 px-0 pb-3">
            {
              studentList?.today.length > 0 ?
              <>
                <div className="column is-full mt-3 pt-0 px-0 pb-1">
                  <h6>Próxima clase</h6>
                </div>

                {
                  studentList?.today?.map((appointment, index) => {
                    const { titulo, ubicacion, fecha, hora, observaciones } = appointment;

                    return (
                      <StudentCard
                        titulo={titulo}
                        ubicacion={ubicacion}
                        fecha={fecha}
                        hora={hora}
                        observaciones={observaciones}
                      />
                    )
                  })
                }
              </>
              :
              <div className="column is-full mt-2 mb-2 pt-0 px-0 pb-1">
                <p>No tiene próximas horas agendadas</p>
              </div>
            }
          

            {
              !_.isEmpty(studentList?.last) ?
              <>
                <div className="column is-full mt-3 pt-0 px-0 pb-1">
                  <h6>Última clase</h6>
                </div>

                <StudentCard
                  titulo={studentList?.last?.titulo}
                  ubicacion={studentList?.last?.ubicacion}
                  fecha={studentList?.last?.fecha}
                  hora={studentList?.last?.hora}
                  observaciones={studentList?.last?.observaciones}
                />
              </>
              :
              <></>
            }

            {
              !_.isEmpty(studentList?.last) && studentList?.previous.length > 0 ?
              <>
                <div className="column is-full mt-3 pt-0 px-0 pb-1">
                  <h6>Clases anteriores</h6>
                </div>

                {
                  studentList?.previous?.map((appointment, index) => {
                    const { titulo, ubicacion, fecha, hora, observaciones } = appointment;

                    return (
                      <StudentCard
                        titulo={titulo}
                        ubicacion={ubicacion}
                        fecha={fecha}
                        hora={hora}
                        observaciones={observaciones}
                      />
                    )
                  })
                }
              </>
              :
              <></>
            }
          </div>
        </>
        :
        <p>Loading...</p>
      }
    </div>
  )
}
