import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want

import { getAppointmentsDateFromApi, getCalendarAppointmentsFromApi } from "../../redux/thunk/appointments";
import { getDataFromToken } from "../../functions/utils";
import { CalendarList } from "../../components";

registerLocale("es", es); // register it with the name you want

export const CalendarView = () => {
  //
  const dispatch = useDispatch();
  const { today } = useSelector(state => state.user)
  const { isLoading, calendarList, calendarViewHighlights } = useSelector(state => state.appointments)
  
  //
  const [fecha, setFecha] = useState(new Date(`${today}T00:03`));
  const id = getDataFromToken('id');

  //
  useEffect(() => {
    calendarClickHandler(fecha)

    dispatch(getAppointmentsDateFromApi(id, `${today.split('-')[0]}-${today.split('-')[1]}-01`, `${today.split('-')[0]}-${today.split('-')[1]}-31`))
  }, [])
  

  //
  const calendarClickHandler = date => {
    setFecha(new Date(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T23:00-0300`));

    dispatch(getCalendarAppointmentsFromApi(id, `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`))
  }

  const calendarChangeMonth = date => {
    dispatch(getAppointmentsDateFromApi(
      id,
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-01}`,
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-31}`
    ))
  }

  return (
    <div className="home inner-container container pb-4">

      <div className="columns is-multiline pt-3 px-0 pb-3">
        <div className="column is-full pt-0 px-0 pb-1">
          <h4>Calendario</h4>
        </div>

        <div className="main-calendar column is-full pt-0 px-0 pb-5">
          <DatePicker
            selected={fecha}
            onChange={(date) => calendarClickHandler(date)}
            inline
            adjustDateOnChange
            locale={es}
            highlightDates={[{"react-datepicker__day--highlighted-custom": calendarViewHighlights}]}
            onMonthChange={calendarChangeMonth}
          />
        </div>

          {
            !isLoading ?
            <div className="column is-full pt-0 px-0 pb-1">
              {
                calendarList.length > 0 ?
                  <CalendarList
                    list={calendarList}
                    date={fecha ? fecha : new Date()}
                  />
                  :
                  <p>No tienes horas agendadas</p>
              }
            </div>
            :
            <></>
          }

      </div>
        
    </div>
  )
}
