import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  studentsList: [],
  studentsViewList: [],
  viewing: {},
  editing: {},
  creating: {
    id: '', 
    nombre: '',
    'fecha_nac': '',
    genero: '',
    edad: 0,
    rut: '',
    telefono: '',
    email: '',
    direccion: '',
    password: '',
    'profesor_id': '',
  },
  isLoading: true,
}

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    endLoading: (state) => {
      state.isLoading = false
    },
    getStudentsList: (state, action) => {
      state.studentsList = action.payload
    },
    getEditingStudent: (state, action) => {
      state.editing = action.payload
    },
    getStudentsViewList: (state, action) => {
      state.studentsViewList = action.payload
    },
    getViewingStudent: (state, action) => {
      state.viewing = action.payload
    },
    creatingStudent: (state, action) => {
      state.creating = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  startLoading,
  endLoading,
  getStudentsList,
  getEditingStudent,
  getStudentsViewList,
  getViewingStudent,
  creatingStudent,
 } = studentsSlice.actions

export default studentsSlice.reducer