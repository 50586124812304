import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { replaceTick } from '../../functions/utils';
import {
  insertNewAppointmentFromApi,
  updateAppointmentFromApi,
  deleteAppointmentFromApi,
} from '../../redux/thunk/appointments';
import {
  insertNewStudentFromApi,
  updateStudentFromApi,
  deleteStudentFromApi,
} from '../../redux/thunk/students';


export const ConfirmPrompt = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { from, action, type } = location.state;

  // * -------------------------------------------------------
  const { creating: creatingAppointment, viewing: viewingAppointment } = useSelector(state => state.appointments);
  const { creating: creatingStudent, viewing: viewingStudent } = useSelector(state => state.students);
  const dispatch = useDispatch();

  const methods = {
    hora: {
      creacion: insertNewAppointmentFromApi,
      edicion: updateAppointmentFromApi,
      eliminacion: deleteAppointmentFromApi,
    },
    alumno: {
      creacion: insertNewStudentFromApi,
      edicion: updateStudentFromApi,
      eliminacion: deleteStudentFromApi,
    },
  }

  let sentence = '';
  let methodToUse;
  let objectToUse;

  switch (type) {
    case 'hora':
      sentence = 'de la hora seleccionada?';
      methodToUse = methods['hora'][replaceTick(action)]

      switch(action) {
        case 'creación':
        case 'edición':
          objectToUse = creatingAppointment;
          break;
  
        case 'eliminación':
          objectToUse = viewingAppointment.id;
          break;
  
        default:
          break;
      }
      break;

    case 'alumno':
      sentence = 'del alumno seleccionado?';
      methodToUse = methods['alumno'][replaceTick(action)]

      switch(action) {
        case 'creación':
        case 'edición':
          objectToUse = creatingStudent;
          break;
  
        case 'eliminación':
          objectToUse = viewingStudent.id;
          break;
  
        default:
          break;
      }
      break;
  
    default:
      break;
  }
  

  function handleClick() {
    dispatch(methodToUse(objectToUse));

    navigate("/checkout", { state: { action, type } });
  }

  return (
    <div className="checkout container">
      <div className="columns is-multiline">
        <div className="column is-full pt-0 px-0 pb-5">
          <h5 className='has-text-weight-normal'>¿Deseas confirmar la <strong>{ action }</strong> { sentence }</h5>
        </div>
        
        <div className="column is-full is-align-content-center pt-0 px-0 pb-3">
          <button onClick={handleClick} className='form-btn'>Agendar</button>
        </div>
        <div className="column is-full is-align-content-center pt-0 px-0 pb-3">
          <Link to={{ pathname: from, state: { from: location.pathname } }} className='link'>Volver</Link>
        </div>
      </div>
    </div>
  )
}
